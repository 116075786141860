import Layout from "../../components/Layout";
import Link from "next/link";
import classNames from "classnames";
import { useRouter } from "next/router";
import useSWR from "swr";
import Head from "next/head";

import api, { fetchTenantConfig } from "../../services/api";

import MediaSections from "../../components/MediaSections";
import NoContentPlaceholder from "../../components/NoContentPlaceholder";
import { useAuth, singleOrganization } from "../../hooks/useAuth";
import { formatInsertables } from "../../util";
import { useEffect } from "react";

export default function Catalog({ initialData, initialTenant }) {
  const router = useRouter();
  const { festivalId, tenant, api, apiLoaded, colorPalette, token } = useAuth();

  const {
    data: catalogResponse,
    error,
    mutate
  } = useSWR(
    apiLoaded &&
      `watch/catalog?event_bucket=${tenant.event_bucket}${
        (tenant?.virtual_protected_tag_list || tenant?.virtual_ott_pass_tags) && token ? `&token=${token}` : ""
      }`,
    k => api.get(k).then(r => r.data),
    { initialData }
  );
  useEffect(() => {
    if (apiLoaded) mutate(initialData, false);
    if (tenant?.virtual_protected_tag_list || tenant?.virtual_ott_pass_tags) {
      setTimeout(() => mutate(), 100);
    }
  }, [apiLoaded]);

  const redirectDestination = tenant?.virtual_festival_force_catalog_redirect;

  useEffect(() => {
    if (redirectDestination) {
      router.replace(redirectDestination);
    }
  }, [redirectDestination]);

  if (
    festivalId !== router.query.festivalId ||
    (!catalogResponse && !error) ||
    redirectDestination
  ) {
    return <Layout loader />;
  }

  return (
    <Layout>
      <Head>
        <title>
          {tenant.virtual_festival_catalog_label || "Catalog"} |{" "}
          {tenant.virtual_festival_display_name}
        </title>
      </Head>
      <div>
        {tenant.virtual_festival_catalog_lead ||
        tenant.virtual_festival_catalog_body ? (
          <div className="text-gray-100 text-center bg-gray-700 p-4 rounded-lg mb-8">
            <div className="font-semibold no-translate">
              {formatInsertables(tenant.virtual_festival_catalog_lead, {
                name: () => tenant.virtual_festival_display_name
              })}
            </div>
            <div className="no-translate">
              {formatInsertables(tenant.virtual_festival_catalog_body, {
                livestreams: t => (
                  <Link
                    href="/[festivalId]/live"
                    as={`/${router.query.festivalId}/live`}
                  >
                    <a className="underline hover:text-gray-200">{t}</a>
                  </Link>
                ),
                link: (url, label) => (
                  <a
                    className="underline hover:text-gray-200"
                    href={url}
                    target="_blank"
                  >
                    {label}
                  </a>
                )
              })}
            </div>
          </div>
        ) : null}
        {catalogResponse?.sections.length ? (
          <MediaSections
            sections={catalogResponse.sections}
            festivalId={router.query.festivalId}
            colorPalette={colorPalette}
          />
        ) : (
          <NoContentPlaceholder
            text={
              error ? (
                `Unable to load catalog: ${error.message}`
              ) : (
                <>No available content &ndash; check back soon</>
              )
            }
            emoji="🍿"
          />
        )}
      </div>
    </Layout>
  );
}

export async function getServerSideProps({ query, res, locale }) {
  if (res) {
    res.setHeader("Cache-Control", "s-maxage=1, stale-while-revalidate");
  }

  let tenant;
  try {
    tenant = await fetchTenantConfig(query.festivalId);
  } catch (e) {
    console.log(
      `Unable to load tenant config for ${query.festivalId}: ${e.message}`
    );
    res.writeHead(404, { "Content-Type": "text/plain" });
    res.end(`Unable to retrieve ${query.festivalId}: ${e.message}`);
    return { props: {} };
  }

  let redirectDestination = tenant?.virtual_festival_force_catalog_redirect;

  // Single org mode where trying to view a different org - prevent Google
  // from indexing the wrong site.
  if (singleOrganization && singleOrganization.id !== tenant.owner) {
    redirectDestination = `https://watch.eventive.org/${query.festivalId}`;
  }

  if (redirectDestination && res) {
    res.writeHead(307, {
      Location: redirectDestination
    });
    res.end();
    return { props: {} };
  }

  let finalProps;
  try {
    const data = await api
      .get(`watch/catalog?event_bucket=${tenant.event_bucket}`, {
        auth: { username: tenant.api_key }
      })
      .then(r => r.data);
    finalProps = { initialData: data, initialTenant: tenant };
  } catch (e) {
    finalProps = {};
  }
  return {
    props: {
      ...finalProps
    }
  };
}
